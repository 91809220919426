
              @import "@/assets/css/variables.scss";
            





















































































































// @import '@/assets/css/pages/iblevel.scss';
.ib_level {
  margin-top: 50px;

  .level_item {
    height: 100%;
    background: #FBFBFB;
    box-shadow: 0px 12px 24px rgba(10, 10, 31, 0.04);
    border-radius: 10px;
    padding: 16px 10px 10px 10px;
    width: 226px;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;

    &.level_item_active {
      border: 1px solid $primary;
    }

    .level_top {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span:nth-child(1) {
        display: inline-block;
        font-size: 12px;
        color: $white;
        line-height: 18px;
        background: $primary;
        border-radius: 4px;
        padding: 3px 6px;
      }

      span:nth-child(2) {
        font-size: 12px;
        color: #6e6e73;
        line-height: 18px;
        @include rtl-sass-prop(margin-left, margin-right, 8px);
      }
    }

    .level_bottom {
      padding: 16px;
      background: $white;
      border-radius: 10px;
      text-align: center;
      height: inherit;

      span {
        display: inline-block;
        background: $primary;
        padding: 10px;
        border-radius: 50%;
        margin-bottom: 6px;

        .svg-icon {
          font-size: 30px;
        }
      }

      .account_name {
        font-size: 16px;
        color: $black;
        line-height: 24px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &.level_item_ib {
      @include rtl-sass-prop(margin-left, margin-right, 50%);
      @include rtl-sass-prop-dual(transform, translateX(-50%), transform, translateX(50%));
      margin-bottom: 34px;

      .level_bottom {
        span {
          .svg-icon {
            font-size: 36px;
          }
        }

        .account_name {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
      }
    }
  }

  .no_ib {
    p {
      font-size: 14px;
      color: $text-secondary;
      text-align: center;
      margin: 24px 0;
    }
  }
}
